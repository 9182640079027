






























import { Component, Prop, Vue } from 'vue-property-decorator';
import { TimeAllocation } from '../types';
import AppTable from '@/components/AppTable.vue';

@Component({
  components: { AppTable }
})
export default class TimeTable extends Vue {
  @Prop({ type: Boolean }) loading!: boolean;
  @Prop({ type: Array }) list!: TimeAllocation[];

  get headers() {
    return [
      { align: 'left', text: this.$i18n.t('common.name'), value: 'conservatee' },
      { align: 'left', text: this.$i18n.t('common.fortune'), value: 'financialState' },
      { align: 'left', text: this.$i18n.t('common.placement'), value: 'placement' },
      { align: 'left', text: this.$i18n.t('label.conservatorshipBegin'), value: 'begin' },
      { align: 'left', text: this.$i18n.t('label.dateRange'), value: 'start' },
      { align: 'right', text: this.$i18n.t('label.calculatedHours'), value: 'time' }
    ];
  }

  get sum(): string {
    return this
      .list
      .reduce((sum: number, item: TimeAllocation) => sum + item.time, 0)
      .toFixed(2);
  }
}
