









































import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LIST, GET_LIST } from '../store';
import format from '@/filter/dateFormat';
import TimeTable from '../compoenents/TimeTable.vue';
import MonthPicker from '@/components/form/MonthPicker.vue';
import ConservatorSelect from '@/modules/conservator/components/ConservatorSelect.vue';
import { ApiResponse } from '@/components/types';
import { TimeAllocationSearchParams } from '../types';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { AuthorizationMixin } from '@/views/mixin';
import { routeToLocation } from '@/router';

const TimeAllocationStore = namespace('timeAllocation');

@Component({
  components: {
    ErrorAlert,
    TimeTable,
    MonthPicker,
    ConservatorSelect
  }
})
export default class TimeAllocation extends AuthorizationMixin {
  @TimeAllocationStore.Getter(GET_LIST) getByKey!: (key: string) => TimeAllocation[];
  @TimeAllocationStore.Action(FETCH_LIST) fetch!: (data: TimeAllocationSearchParams) => Promise<ApiResponse>;

  loading: boolean = false;
  error: any = null;

  get routeQuery(): TimeAllocationSearchParams {
    return {
      month: this.$route.query['month'],
      conservator: this.$route.query['conservator']
    } as TimeAllocationSearchParams;
  }

  get month() {
    return this.routeQuery.month;
  }

  set month(month: string) {
    if (!month) {
      month = '';
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, month } })).catch(() => {});
  }

  get conservator() {
    return this.routeQuery.conservator;
  }

  set conservator(conservator: string) {
    if (!conservator) {
      conservator = '';
    }

    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, conservator } })).catch(() => {});
  }

  get params(): TimeAllocationSearchParams | undefined {
    if (!this.month || (!this.conservator && this.isAdmin())) {
      return;
    }

    return {
      month: format(this.month, 'yyyy-MM-dd'),
      conservator: this.conservator
    };
  }

  get list() {
    if (!this.params) return [];

    return this.getByKey(`${this.params.conservator}-${this.params.month}`) || [];
  }

  @Watch('params', { immediate: true })
  async onParamsChange(params?: TimeAllocationSearchParams) {
    if (!params) return;

    this.error = null;
    this.loading = true;

    const { error } = await this.fetch(params);

    if (error) {
      this.error = error;
    }

    this.loading = false;
  }

  created() {
    if (this.routeQuery.month) return;

    this.month = format(Date.now(), 'yyyy-MM-dd');
  }
}
